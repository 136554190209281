import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { CheckIcon } from "@heroicons/react/outline";
// import EbookForm from "../components/ebook-form.tsx";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Required"),
});

const features = [
  {
    name: "An Overview",
    description: "Why OFS businesses can be hard to finance",
  },
  {
    name: "Key Materials",
    description: "What to prepare in advance of any loan request",
  },
  {
    name: "A Detailed Process Guide",
    description:
      "How to run a methodical loan request process to maximize results ",
  },
  {
    name: "Where to Turn",
    description:
      "A description of how Catalina Advisors assists clients with corporate finance needs",
  },
];

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function EbookPage() {
  const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);
  const { register, handleSubmit, reset, errors, formState } = useForm({
    resolver: yupResolver(schema),
  });

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = React.useState(
    false
  );

  // const [message, setMessage] = React.useState("");

  const submitForm = async (data) => {
    console.log("Submission starting", data);

    const fullName = data.fullName;
    const firstName = fullName.split(" ").slice(0, -1).join(" ");
    const lastName = fullName.split(" ").slice(-1).join(" ");

    const result = await addToMailchimp(data.email, {
      FNAME: firstName,
      LNAME: lastName,
    });

    console.log("Submitting complete", result.msg);

    setIsSuccessfullySubmitted(true);
    // setMessage({ message: result.msg });

    reset();
  };
  return (
    <>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="OFS Ebook"
      />
      <Layout
        headerStyles="text-lg font-bold leading-none tracking-widest text-green-600 md:text-4xl lg:text-5xl"
        headerTitle={[
          "How to Secure Bank Financing",
          <br key="ebook" />,
          " for Oilfield Service Companies",
        ]}
        mainLayout="h-full bg-white"
      >
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
            <div className="lg:text-center">
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                What you will find inside.
              </p>
            </div>
            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {features.map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-green-600 text-white">
                        <CheckIcon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 xl:py-24  lg:px-8">
            <h2 className="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl">
              Download your free copy of the OFS Ebook here.
            </h2>

            <form onSubmit={handleSubmit(submitForm)} className="mt-8 sm:flex ">
              <div>
                <label htmlFor="emailAddress" className="sr-only">
                  Full Name
                </label>

                <input
                  ref={register({
                    required: true,
                  })}
                  id="fullName"
                  className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input sm:max-w-xs "
                  name="fullName"
                  placeholder="Full Name"
                />

                <div className="block w-full px-4 pt-2 text-xs text-red-500 ">
                  {errors.fullName?.message}
                </div>
              </div>
              <div className="mt-3 sm:mt-0 sm:ml-3">
                <label htmlFor="emailAddress" className="sr-only">
                  Email address
                </label>

                <input
                  ref={register({
                    required: true,
                  })}
                  id="email"
                  className="block w-full px-4 py-3 placeholder-gray-500 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-green-500 focus:outline-none focus:border-green-300 focus:shadow-outline-green form-input sm:max-w-xs "
                  name="email"
                  placeholder="Enter you email"
                />

                <div className="block w-full px-4 pt-2 text-xs text-red-500 ">
                  {errors.email?.message}
                </div>
              </div>

              <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button
                  type="submit"
                  disabled={formState.isSubmitting || isSuccessfullySubmitted}
                  className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                >
                  Submit
                </button>
                <div className="block w-full px-4 pt-2">
                  <motion.svg
                    initial={"hidden"}
                    animate={formState.isSubmitting ? "visible" : "hidden"}
                    variants={variants}
                    className="w-5 h-5 mr-3 -ml-1 text-black animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </motion.svg>
                </div>
              </div>

              <motion.div
                style={{ maxHeight: 50 }}
                initial={"hidden"}
                animate={isSuccessfullySubmitted ? "visible" : "hidden"}
                variants={variants}
                className="success flex flex-wrap items-center text-sm sm:ml-3 mt-3 sm:mt-0 "
                // dangerouslySetInnerHTML={{
                //   __html: JSON.stringify(message.message),
                // }}
              >
                <p>
                  Form submitted successfully. Thank You!&nbsp;
                  <br />
                  <ul>
                    {data.allFile.edges.map((file, index) => {
                      return (
                        <li key={`pdf-${index}`}>
                          <a
                            className=" border-b-2 border-transparent border-green-600 hover:border-green-500 hover:text-green-500 text-green-600"
                            href={file.node.publicURL}
                            download
                          >
                            {file.node.name}.pdf
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </p>
              </motion.div>
            </form>
          </div>
        </div>
        <div className="py-16 xl:py-24 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
          <div className="max-w-max lg:max-w-7xl mx-auto">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="text-base max-w-prose lg:max-w-none">
                <h2 className="leading-6 text-green-600 font-semibold tracking-wide uppercase">
                  About
                </h2>
                <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                  The Author
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="relative md:bg-white md:p-6">
                <div className="lg:grid lg:grid-cols-2 lg:gap-6">
                  <div className="prose prose-green prose-lg text-gray-500 lg:max-w-none">
                    <p>
                      Phillip Bannon is the Founder and CEO of Catalina
                      Advisors, a consulting and advisory firm that specializes
                      in helping startups and mid-size companies navigate the
                      challenges associated with capital sourcing and corporate
                      finance.
                    </p>
                    <p>
                      Catalina takes a consultative approach with clients,
                      helping them access financing in challenging markets while
                      ensuring the terms and structure of a deal work for all
                      parties.
                    </p>
                  </div>
                  <div className="mt-6 prose prose-green prose-lg text-gray-500 lg:mt-0">
                    <p>
                      Prior to founding Catalina, Phillip worked in corporate
                      banking with a focus on oilfield services, rail, chemical
                      manufacturing, and general industrial.
                    </p>
                    <p>
                      Phillip is a Houston native with an undergraduate degree
                      in finance from the University of Houston and an MBA from
                      Rice University where he was a Jones Scholar.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EbookPage;
